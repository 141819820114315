import React, { useEffect, useRef, useState } from 'react';
import { Container, RichText, Media } from '@sprinklr/shared-lib';
import AccordionContext from '@sprinklr/shared-lib/components/accordion/AccordionContext';
import AccordionBasicTemplate from '../accordionBasicTemplate';
import { AccordionDeluxeTemplateProps } from './types';
import { useContainerPadding, usePrevious } from '@sprinklr/shared-lib/hooks';
import { Themed, Box, Grid } from 'theme-ui';
import { keyframes } from '@emotion/react';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { assignDefaultProp } from '@sprinklr/shared-lib/utils/assignDefaultProp';

const AccordionDeluxeTemplate = (props: AccordionDeluxeTemplateProps) => {
  const {
    id,
    accordionRows,
    headline,
    noContainerLeftRightPadding,
    topMargin,
    bottomMargin,
    singleImageAsset,
    textContent,
    accordionDeluxeTemplateSx,
  } = props;

  const contentSplit = assignDefaultProp(props.contentSplit, '1/3');
  const textPosition = assignDefaultProp(props.textPosition, 'RIGHT');
  const startOpen = assignDefaultProp(props.startOpen, true);

  // Logic for setting media in next few sections
  const rowsWithImage = accordionRows?.filter(row => !!row.rowImage);
  const rowImageExists = rowsWithImage?.length > 0;
  const allRowsHaveImages = rowsWithImage?.length === accordionRows?.length;

  const firstRowWithImage = accordionRows?.find(row => !!row.rowImage);
  const defaultImage = allRowsHaveImages
    ? firstRowWithImage?.rowImage
    : singleImageAsset || firstRowWithImage?.rowImage;

  const bpIndex = useBreakpointIndex();
  let isDesktop = false;
  if (bpIndex >= 3) isDesktop = true;

  const [openItemIndex, setOpenItemIndex] = useState(0);
  const contextVal = { setOpenItemIndex };
  const prevOpenItemIndex = usePrevious(openItemIndex);

  const [mediaSource, setMediaSource] = useState(defaultImage);

  useEffect(() => {
    if (allRowsHaveImages && openItemIndex >= 0) {
      // Wait until row closes to fade in new image - initial load prevOpenItemIndex === undefined
      prevOpenItemIndex === undefined
        ? setMediaSource(accordionRows[openItemIndex]?.rowImage)
        : setTimeout(
            () => setMediaSource(accordionRows[openItemIndex]?.rowImage),
            200,
          );
    }
  }, [openItemIndex]);

  // Set styles based off props
  let contentSplitVal;
  if (contentSplit === '1/3') contentSplitVal = '1fr 2fr';
  if (contentSplit === '1/2') contentSplitVal = '1fr 1fr';
  if (contentSplit === '2/3') contentSplitVal = '2fr 1fr';
  if (!defaultImage) contentSplitVal = '1fr';

  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const containerSx = {
    pt: pt,
    pb: pb,
    ...accordionDeluxeTemplateSx,
  };

  // Inspo: https://www.charistheo.io/blog/2021/02/restart-a-css-animation-with-javascript/#restarting-a-css-animation
  const mediaContainerRef = useRef();
  const mediaContainerEl = mediaContainerRef?.current;

  if (allRowsHaveImages && prevOpenItemIndex !== openItemIndex) {
    mediaContainerEl?.classList.remove('fade-in-media-container');
    mediaContainerEl?.classList.add('fade-out-media-container');

    // Wait until row closes to fade in new image
    setTimeout(() => {
      mediaContainerEl?.classList.remove('fade-out-media-container');
      mediaContainerEl?.classList.add('fade-in-media-container');
    }, 200);
  }

  const fadeIn = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `;

  const fadeOut = keyframes`
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  `;

  // Component props
  const accordionBasicProps = {
    accordionRows: accordionRows,
    rowShouldDisplayImage: rowImageExists,
    noContainerLeftRightPadding: true,
    noContainerTopBottomPadding: true,
    accordionDisplayStyle: 'CLEAN',
    startOpen,
    topMargin: 'NONE',
    bottomMargin: 'NONE',
  };

  const mediaProps = {
    source: mediaSource,
    altTitle: mediaSource?.description || mediaSource?.title,
    mediaType: 'IMAGE',
  };

  return (
    <AccordionContext.Provider value={contextVal}>
      <Container
        id={id}
        fullWidth={noContainerLeftRightPadding}
        containerSx={containerSx}
      >
        <Grid
          sx={{
            gridTemplateColumns: ['1fr', null, null, contentSplitVal],
            gridColumnGap: defaultImage ? [0, null, null, 5] : 0,
            '.fade-in-media-container': {
              animation: `${fadeIn} .2s linear`,
            },
            '.fade-out-media-container': {
              animation: `${fadeOut} .2s linear`,
            },
          }}
        >
          {isDesktop && defaultImage && (
            <Box ref={mediaContainerRef}>
              <Media
                {...mediaProps}
                mediaSx={{
                  alignSelf: 'start',
                  gridColum: textPosition === 'LEFT' ? 2 : 1,
                  gridRow: 1,
                }}
              />
            </Box>
          )}
          <Box sx={{ gridColumn: textPosition === 'LEFT' ? 1 : 2, gridRow: 1 }}>
            <Box sx={{ mb: [3, 4, null, 5] }}>
              <Themed.h2>{headline}</Themed.h2>
              <RichText richTextObject={textContent} />
            </Box>
            {!isDesktop && !rowImageExists && defaultImage && (
              <Box sx={{ mb: [3, 4, null, 5] }}>
                <Media {...mediaProps} />
              </Box>
            )}
            <AccordionBasicTemplate {...accordionBasicProps} />
          </Box>
        </Grid>
      </Container>
    </AccordionContext.Provider>
  );
};

export default AccordionDeluxeTemplate;
